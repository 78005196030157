.close-button {
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  color: #000;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  width: 20%;
}

.alert {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 200;
}

.alert-modal {
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 0.5rem 0.5rem 1rem 1rem;
  margin-bottom: 0.5rem;
  transition: margin-right 3s ease-out;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  background-color: white;
  justify-content: space-between;
  min-width: 15rem;
  max-width: 15rem;
}

.alert-message-design {
  font-size: 0.8rem;
  line-height: 1.5;
  margin: 0;
  word-wrap: break-word;
}

.green {
  border-top: 0.5rem solid #14d9a9;
}

.red {
  border-top: 0.5rem solid #fb5012;
}

.yellow {
  border-top: 0.5rem solid #e9df00;
}
