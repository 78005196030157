.menu {
  position: fixed;
  top: 4rem;
  right: 3rem;
  width: 16rem;
  z-index: 200;
  overflow-y: scroll;
  border-radius: 0.5rem;
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.15);
  background-color: #fff;
  padding: 0.75rem;
}

.header {
  display: flex;
  justify-content: space-between;
}
.header h3 {
  font-size: 1.2rem;
  font-weight: 700;
  margin: auto 0;
}

.header p {
  font-size: 0.8rem;
  margin: auto 0;
  color: #b6b5b2;
  cursor: pointer;
}

.tab {
  display: flex;
  gap: 0.5rem;
  margin: 0.5rem 0;
}

.tab p {
  margin: 0;
  font-size: 0.8rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}

.menu ul {
  padding: 0;
  max-height: 26rem;
}

.menu ul h6 {
  text-align: center;
  margin: 1rem;
}
.notification {
  margin: 0;
  height: 4rem;
  padding: 0.2rem;
  border-radius: 0.2rem;
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.notification p {
  margin: 0;
}
.notification:hover {
  background-color: grey;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 95%;
}

.message {
  word-break: break-all;
  font-size: 0.8rem;
}
.time {
  font-size: 0.6rem;
  text-align: right;
  font-weight: 500;
}

.active {
  color: white;
  background-color: #214390;
}

.status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5%;
  padding: 0;
}
.circle {
  background-color: #214390;
  border-radius: 50%;
  width: 0.6rem;
  height: 0.6rem;
}
