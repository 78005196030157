.radio-button {
  font-family: system-ui, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  line-height: 2;
  display: grid;
  grid-template-columns: 1.5em auto;
  gap: 0.5em;
  cursor: pointer;
}

.fs-7 {
  font-size: 1rem;
}

.link {
  text-decoration: none !important;
  color: black !important;
  margin: 0rem 1rem;
}

.group {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


