.header {
  background-color: #b6b5b2;
  min-height: 4rem;
  width: 100%;
  font-weight: bold;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 3.2rem;
}

.header h1 {
  font-size: 1.5rem;
  margin: 0;
  font-weight: 700;
  color: white;
}

.header h2 {
  font-size: 1.5rem;
  margin: 0;
  font-weight: 700;
  color: white;
}

.header p {
  font-size: 0.6rem;
  margin: 0;
  font-weight: 400;
  color: white;
}
.line {
  border-left: 0.15rem solid white;
  height: 2rem;
}

.edit-nav {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.edit-back {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.btn-logout {
  background-color: #152a5c;
  border-radius: 0.5rem;
  color: white;
  padding: 0.5rem 2rem;
}
