.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 110;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 120;
  width: 65%;
  height: 65%;
}

.modal-content {
  background-color: #eeeeee;
  margin: auto;
  padding: 1rem;
  border-radius: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.modal-content h2 {
  font-size: 1.8rem;
  margin: 0;
}
.modal-content hr {
  margin: 1rem 0;
}
.message {
  margin: 0;
  font-size: 0.8rem;
  text-align: justify;
  word-break: break-all;
  white-space: pre-wrap;
  flex-grow: 1;
}

.modal-content p {
  margin: 0;
  font-size: 0.8rem;
}
.close-button {
  background-color: #152a5c;
  border-radius: 0.5rem;
  color: white;
  padding: 0.5rem 1.5rem;
}

.toggle {
  text-decoration: underline;
  cursor: pointer;
}

.more {
  height: 85%;
}

.scroll {
  overflow: auto;
}
