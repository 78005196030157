.form {
  background-color: white;
  width: 40rem;
  max-width: 80%;
  border-radius: 0.25rem;
  padding: 1rem;
}

.form p {
  margin: 0;
  display: flex;
  border-bottom: #e5e5e5 0.1rem solid;
}
.form p label {
  width: 25%;
  padding: 0 0 1rem 0;
}
.form p input {
  background-color: white;
  border: none;
  padding: 0 0 1rem 0;
  width: 75%;
}

.form p input:disabled {
  color: #a59492;
}

.form p:not(:first-of-type) input,
.form p:not(:first-of-type) label,
.form p:not(:first-of-type) select {
  padding-top: 1rem;
}

.form p select {
  background-color: white;
  border: none;
  padding: 0 0 1rem 0;
  width: 75%;
}

.form label {
  font-size: 1rem;
  font-weight: 700;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

.actions {
  display: flex;
  gap: 1rem;
  padding-top: 2rem;
}

.actions button {
  width: 50%;
  height: 2rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
  border: none;
  font-size: 0.8rem;
  font-weight: 600;
  color: white;
  cursor: pointer;
}

.dark {
  background-color: #152a5c;
}

.light {
  background-color: #5472b8;
}

.alert {
  font-size: 0.6rem;
  text-align: center;
  color: red;
  margin: 0.25rem 0 0 0;
}

.title {
  font-size: 1.5rem;
  text-align: center;
}
