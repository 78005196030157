.menu {
  display: flex;
  justify-content: space-between;
}

.block {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 0.5rem;
}
.navigation {
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-end;
}
.navigation button {
  width: 100%;
  background-color: #4472c4;
}
.menu button {
  color: white;
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem;
  font-weight: 500;
}

.actions {
  display: flex;
  justify-content: space-between;
  gap: 5%;
}
.margin-auto {
  margin: auto;
}
.actions button {
  flex-grow: 1;
}
.actions select {
  flex-grow: 1;
  background-color: #152a5c;
  color: white;
  border-radius: 0.5rem;
  text-align: center;
  padding: 0.5rem;
}

.actions select option {
  background-color: white;
  padding: 1rem;
  text-align: center;
  color: black;
}

.add {
  background-color: #152a5c;
}

.update {
  background-color: #4f964f;
}

.template {
  background-color: #4472c4;
}
.cancel {
  background-color: #dd3333;
}

.add-current {
  background-color: #152a5c;
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: white;
  border: none;
}
