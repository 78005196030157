.table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  font-size: 0.8rem;
  text-align: left;
}
.table-bg {
  background-color: #f1f1f1;
  border-radius: 0.5rem;
  padding: 1rem;
  overflow-x: auto;
}
.table th {
  border: none;
  padding: 0.5rem 1rem;
  font-weight: normal;
  width: 16.67%;
}

.table td {
  margin: 1rem;
  background-color: #f9f9f9;
  border-top: 0.1rem solid #b6b5b2;
  padding: 0.8rem 1rem;
}


.table tr {
  margin: 1rem;
}
.center {
  text-align: center;
}

.pending {
  border: 0.15rem solid #ffc107;
  color: #ffc107;
  background-color: #ffeebb;
}
.returned {
  border: 0.15rem solid #ff5722;
  color: #ff5722;
  background-color: #ffc5b2;
}
.for-approval {
  border: 0.15rem solid #2196f3;
  color: #2196f3;
  background-color: #cce8ff;
}
.disapproved {
  border: 0.15rem solid #ff0000;
  color: #ff0000;
  background-color: #ffc6c6;
}
.for-invoicing {
  border: 0.15rem solid #673ab7;
  color: #673ab7;
  background-color: #d8c1ff;
}

.invoiced {
  border: 0.15rem solid #673ab7;
  color: #673ab7;
  background-color: #d8c1ff;
}
.complete {
  border: 0.15rem solid #8bc34a;
  color: #8bc34a;
  background-color: #ddfdb7;
}
.done {
  border: 0.15rem solid #8bc34a;
  color: #8bc34a;
  background-color: #ddfdb7;
}

.issued-or {
  border: 0.15rem solid #8bc34a;
  color: #8bc34a;
  background-color: #ddfdb7;
}
.status {
  border-radius: 0.5rem;
  padding: 0.3rem 1rem;
  margin: auto;
  width: 6rem;
  font-size: 0.6rem;
  font-weight: 500;

}

.action-div {
  display: flex;
  justify-content: space-evenly;
}
.action-btn {
  display: flex;
  gap: 0.2rem;
}
.action-btn p {
  margin: 0;
  font-size: 0.6rem;
}

.link {
  color: #2196f3;
}

.buttons {
  font-size: 20;
}