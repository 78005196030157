.table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  font-size: 0.8rem;
  text-align: left;
}
.table-bg {
  background-color: #f1f1f1;
  border-radius: 0.5rem;
  padding: 1rem;
  overflow-x: auto;
}
.table th {
  border: none;
  padding: 0.5rem 1rem;
  font-weight: normal;
  width: 16.67%;
}

.table td {
  margin: 1rem;
  background-color: #f9f9f9;
  border-top: 0.1rem solid #b6b5b2;
  padding: 0.8rem 1rem;
}

.action-div {
  display: flex;
  justify-content: space-evenly;
}
.action-btn {
  display: flex;
  gap: 0.2rem;
}
.action-btn p {
  margin: 0;
  font-size: 0.6rem;
}

.actions {
  display: flex;
  gap: 1rem;
}
.search-bar {
  background-image: url("../assets/search.svg");
  background-position: 0.75rem center;
  background-repeat: no-repeat;
  width: 20rem;
  background-color: #f1f1f1;
  border-radius: 0.5rem;
  border: none;
  padding: 1rem 2rem 1rem 2.5rem;
}

.add {
  background-color: #152a5c;
  color: white;
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  font-weight: 600;
  font-size: 0.8rem;
  margin-left: auto;
}
.select {
  background-color: #152a5c;
  color: white;
  border-radius: 0.5rem;
  padding: 1rem;
  font-size: 0.8rem;
  font-weight: 600;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 110;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 120;
  max-width: 40rem;
}

.modal-content {
  background-color: white;
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 15rem;
  width: 100%;
}

.modal-content img {
  height: 5rem;
  width: 5rem;
  margin: auto;
}

.modal-content h1 {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
}

.actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.btn-confirm {
  background-color: #152a5c;
  border-radius: 0.5rem;
  color: white;
  padding: 0.5rem 1.5rem;
  border: none;
  cursor: pointer;
}
.btn-cancel {
  background-color: #dd3333;
  border-radius: 0.5rem;
  color: white;
  padding: 0.5rem 1.5rem;
  border: none;
  cursor: pointer;
}
