.main {
  width: 90%;
  margin: auto;
  margin-top: 1rem;
  gap: 0;
}
.navigation {
  display: flex;
}
.radios {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 150px;
}
.radios p {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 8px;
  width: 100%;
  padding-left: 214px;
}
.radio {
  display: flex;
  width: 50%;
  margin: auto;
  align-items: center;
  text-align: center;
  margin-left: 410px;
}

.radio-button {
  font-family: system-ui, sans-serif;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 2;
  display: grid;
  grid-template-columns: 1.5em auto;
  gap: 0.5em;
}
.radios label {
  margin-left: 10%;
}

.buttons {
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.buttons button {
  background-color: #4472c4;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 500;
  margin-right: 16px;
  width: 100%;
}

@media (max-width: 768px) {
  .buttons {
    width: 40%;
  }
  .radios label {
    margin-left: 0;
  }
  .radio {
    flex-direction: column;
  }
}

.table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  font-size: 70%;
}

.table th {
  background-color: black;
  color: white;
}

.table td,
table th,
.table tr {
  border: 0.05rem solid black;
  padding: 0.25rem;
}

.name {
  text-align: left;
  padding-left: 2%;
}


