.main {
  background-color: #e9ecef;
  margin: 0;
  padding: 2rem 3rem;
  overflow: hidden;
  align-items: center;
}

.shadow {
  border-radius: 0.25rem;
  width: 30rem;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  background-color: white;
}
