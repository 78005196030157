.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 110;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 120;
  width: 65%;
  height: max-content;
  box-sizing: border-box;
}

.modal-content {
  background-color: #eeeeee;
  margin: auto;
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.modal-content h2 {
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
}
.modal-content h2 p {
  display: inline-block;
  font-weight: 400;
  margin: 0;
}
.modal-content hr {
  margin: 1rem 0;
}

.input {
  border-radius: 0.25rem;
  border: none;
  font-size: 0.8rem;
  padding: 0.5rem;
  color: black;
  background-color: #d9d9d9;
  height: 2.5rem;
}

.search {
  width: 100%;
}

.search-bar {
  background-image: url("../../assets/search.svg");
  background-position: 0.75rem center;
  background-repeat: no-repeat;
  padding-left: 2rem;
  margin-bottom: 1rem;
  width: 100%;
}

.textarea {
  overflow: auto;
  min-height: 10rem;
  max-height: 25rem;
  resize: vertical;
  font-family: "Roboto", sans-serif;
}

.list {
  background-color: #b4b4b4;
  border-radius: 0.25rem;
  margin: 0;
  padding: 0;
  position: fixed;
  z-index: 120;
  width: calc(100% - 2rem);
}

.list li {
  padding: 0.5rem 1rem;
  margin: 0;
  color: white;
  cursor: pointer;
}
.list li:hover {
  background-color: #a9a9a9;
}
.list li:not(:last-child) {
  border-bottom: 0.1rem solid white;
}

.list li:first-child {
  border-radius: 0.25rem 0.25rem 0 0;
}
.list li:last-child {
  border-radius: 0 0 0.25rem 0.25rem;
}

.selected {
  background-color: #d9d9d9;
  border-radius: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-size: 0.8rem;
  height: 2.5rem;
}

.selected p {
  margin: 0;
}
.selected button {
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
  border: none;
}

.actions {
  display: flex;
  gap: 1rem;
}
.btn-submit {
  background-color: #152a5c;
  border-radius: 0.5rem;
  color: white;
  padding: 0.5rem 1.5rem;
  border: none;
}
.btn-close {
  background-color: #dd3333;
  border-radius: 0.5rem;
  color: white;
  padding: 0.5rem 1.5rem;
  border: none;
}
.error-text {
  color: red;
  font-size: 0.8rem;
  margin: 0;
}
.error {
  background-color: #ffe4e4;
  border: 0.1rem red solid;
}
input[type="text"]::placeholder,
textarea::placeholder {
  color: black;
}
