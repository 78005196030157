.grid {
  margin: 1rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr 1fr;
  gap: 0.5rem;
  margin-top: 3rem
}

.table {
  grid-column: span 3;
}
.section {
  grid-column: span 3;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 9rem;
}

.border {
  border: 0.05rem solid black;
  padding: 0.5rem;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  justify-content: space-between;
  max-height: 380px;
  overflow-y: auto;
  margin-top: 0rem;
}
.dButton2 {
  font-size: 13px;
}
.top {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px; 
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .border {
    grid-column: span 3;
  }
  .table {
    font-size: 80%;
  }
}




