.table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}

.table th {
  background-color: black;
  color: white;
}
.table td,
.table th {
  border: 0.1rem solid black;
  padding: 0.25rem;
  font-size: 0.8rem;
}

.train {
  background-color: #ddebf7;
}

.station {
  background-color: #f2f2f2;
}

.other {
  background-color: #e2efda;
}

.block {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 0 auto;
  margin-top: 1rem;
  gap: 1rem;
}

.input input {
  display: none;
}
.input {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.input p {
  margin: 0;
}
.input label {
  background-color: #152a5c;
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: white;
  border: none;
  cursor: pointer;
  text-align: center;
}
.block button {
  background-color: #152a5c;
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: white;
  border: none;
}

.description {
  text-align: left;
  padding-left: 1rem !important;
}
.category {
  width: 10%;
}
