.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 110;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 120;
}
.modal img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  cursor: default;
}

.image-container {
  position: relative;
  width: 80%;
}
.modal button {
  position: absolute;
  top: -4%;
  right: -4%;
  border-radius: 50%;
  border: none;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
}
