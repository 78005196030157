.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loading-spinner {
  border: 1rem solid rgba(0, 0, 0, 0.1);
  border-left-color: #152a5c;
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loading-container h2 {
  margin-top: 1rem;
}
