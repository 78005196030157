.background {
  background-color: #f1f1f1;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.login {
  display: flex;
  margin: auto;
  width: 45rem;
  height: 22.5rem;
}

.logo img {
  max-width: 80%;
  max-height: 50%;
}
.logo {
  width: 50%;
  background-color: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form {
  background-color: white;
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  justify-content: center;
}

.form h3 {
  font-size: 1.6rem;
  margin: 0 0 1rem 0;
  color: #152a5c;
  text-align: center;
}
.form label {
  font-size: 0.8rem;
  margin-top: 0.5rem;
  padding-left: 0.5rem;
}

.form input {
  background-color: white;
  border: none;
  border-bottom: 0.125rem #d9d9d9 solid;
  padding: 0;
  padding: 0.5rem 0.5rem 0.25rem 0.5rem;
}

.form button {
  background-color: #152a5c;
  color: white;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  font-weight: 700;
  max-width: max-content;
  margin: 2rem auto;
  border: none;
}
.form a {
  font-size: 0.8rem;
  text-align: center;
  color: black;
  text-decoration: none;
}
.form a:hover {
  color: #152a5c;
  text-decoration: underline;
}
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

.alert {
  font-size: 0.6rem;
  text-align: center;
  color: red;
  margin: 0.25rem 0 0 0;
}
