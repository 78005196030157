.otp-form {
  background-color: white;
  text-align: center;
}

.otp-form h1 {
  color: #152a5c;
  font-size: 1.2rem;
  width: 65%;
  margin: 0 auto;
  margin-top: 3rem;
  text-align: center;
}

.code {
  font-size: 0.8rem;
}

.otp-form input {
  background-color: #f1f1f1;
  border: none;
  font-size: 0.8rem;
  text-align: center;
  font-weight: 700;
  padding: 0.75rem 3rem;
  border-radius: 0.5rem;
  margin: 1rem auto;
  display: block;
}

.resend {
  font-size: 0.6rem;
  font-weight: 400;
}

.resend span {
  font-weight: 700;
  cursor: pointer;
  color: #152a5c;
}
.resend span.disabled-span {
  color: #b6b5b2;
  cursor: not-allowed;
}

.otp-form button {
  background-color: #152a5c;
  color: white;
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  font-size: 0.8rem;
  font-weight: 700;
  margin: 1rem auto 2rem auto;
  cursor: pointer;
  border: none;
  display: block;
}

.otp-form button:disabled {
  cursor: not-allowed;
}

.invalid {
  color: red;
}

.error {
  color: red;
  margin: -1rem auto 0 auto;
  width: 50%;
  font-size: 0.7rem;
}
