html,
body,
#root {
  font-family: "Roboto" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-size: 20px;
  margin-top: 48px;
}

* {
  box-sizing: border-box;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

main {
  margin: 2rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 1;
}

div.button-container {
  text-align: right!important;
}

div.button-container button {
  border: 0 solid #152a5c;
  padding: 10px 16px;
  border-radius: 8px;
  font-size: 16px;
}

div.button-container button:hover{
  background-color: #eceaea;
}

div.button-container-selected {
  text-align: left!important;
}

div.button-container-selected button {
  border: 0 solid #152a5c;
  padding: 10px 16px;
  border-radius: 8px;
  font-size: 16px;
}

div.button-container-selected button:hover{
  background-color: #eceaea;
}

input {
  outline: none;
  box-shadow: none;
}
img {
  cursor: pointer;
}
button[disabled] {
  cursor: not-allowed;
}
button {
  border: none;
  cursor: pointer;
}
select[disabled] {
  cursor: not-allowed;
}

input[disabled] {
  cursor: not-allowed;
}

/* Set the width and height of the scroll bar */
::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

/* Set the background color of the scroll bar track */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Set the color and rounded corners of the scroll bar thumb */
::-webkit-scrollbar-thumb {
  background-color: rgba(176, 176, 176, 0.5);
  border-radius: 0.125rem;
}

/* Hide the scroll bar arrows */
::-webkit-scrollbar-button {
  display: none;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: black;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: black;
}
