.main {
    position: relative;
    width: 100%;
  }
  
  .dButton2 {
    position: absolute;
    top: 0px; 
    right:-5px; 
    z-index: 1; /* Ensure the button appears above the chart */
    background-color: #0070c0; 
    color: #fff; 
    padding: 10px; 
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-top: -5px;
    font-size: 10px;
  }
  
  .dButton2:hover {
    background-color: #005293; 
  }
