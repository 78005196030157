.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 110;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 120;
  max-width: 40rem;
}

.modal-content {
  background-color: white;
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 15rem;
  width: 100%;
}
.modal-content img {
  height: 5rem;
  width: 5rem;
  margin: auto;
}
.modal-content h1 {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
}
.modal-content p {
  margin: 0;
  flex-grow: 1;
}
.actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.btn-confirm {
  background-color: #152a5c;
  border-radius: 0.5rem;
  color: white;
  padding: 0.5rem 1.5rem;
  border: none;
  cursor: pointer;
}
.btn-close {
  background-color: #dd3333;
  border-radius: 0.5rem;
  color: white;
  padding: 0.5rem 1.5rem;
  border: none;
  cursor: pointer;
}
