.main {
  box-sizing: border-box;
  width: 100%;
  margin-top: 1rem;
  padding-bottom: 32px;
}
.navigation {
  display: flex;
  width: 90%;
  margin-top: 16px;
  margin-bottom: -20px;
}

/* .main .currmonthTable {
  display: flex;
  flex-direction: row;
} */

.radios {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.radios p{
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 16px;
}

.radios label {
      margin-left: 10%;
    margin-right: -5%;
}

.radios p {
  text-align: center;
    justify-content: center;
    margin-left: 16%!important;
}

.radio {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 16px;
}
.radios p {
  margin: 0;
}

.radio-button {
  font-family: system-ui, sans-serif;
  font-size: 16px;
  font-weight: bold;
  display: grid;
  line-height: 2;
  grid-template-columns: 1.5em auto;
  gap: 0.5em;
}


.buttons {
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.buttons button {
  background-color: #4472c4;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 500;
  margin-right: 16px;
  width: 100%;
}

.buttons button .back {
  padding-left: 26px!important;
  padding-right: 26px!important;
}

@media (max-width: 768px) {
  .buttons {
    width: 40%;
  }
  .radios label {
    margin-left: 0;
  }
  .radio {
    flex-direction: column;
  }
}

.main .table {
  height: 80% !important;
    /* width: 90%!important; */
    width: 30%!important;
    margin-left: 30%;

}
.main .tableContainer {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  box-sizing: border-box;
  margin-top: -16px;
  width: 98%;
}


.table {
  text-align: center;
  border-collapse: collapse;
  font-size: 16px;
  height: 200px;
  margin-top: 40px;
}

.table th {
  width: 33%;
  background-color: black;
  color: white;
}
.table td,
.table th {
  border: 0.05rem solid black;
  padding: 0.25rem;
  font-size: 0.8rem;
  font-weight: 500;
}

.label {
  text-align: left;
  color: black;
  padding-left: 2%;
}
.actual {
  background-color: #0070c0;
  color: white;
  text-align: right;
}

.budget {
  background-color: #ffc000;
  color: white;
  text-align: right;
}
