.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 1rem;
  font-weight: 700;
}

.top p {
  margin: 0;
}

.table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  flex-grow: 0.75;
}

.table thead th {
  font-size: 0.8rem;
  background-color: #4472c4;
  color: white;
  border-style: solid;
  border-color: #4472c4;
  border-width: 0.05rem;
  padding: 0.5rem;
}

.table tbody td {
  font-size: 0.7rem;
  padding: 0.6rem;
  border-color: grey;/*added border, color modified & text align left*/
  border-width: 0.05rem;
  border-style: solid;
  text-align: left;
}

.bottom {
  font-style: italic;
  font-size: 0.8rem;
  margin: 0;
}

button{
  border: 0 solid #152a5c;
  padding: 8px 10px;
  background-color: #0070c0; 
  color: #fff; 
  border-radius: 10px;
}

button:hover {
  background-color: #005293; 
}

.appButton {
  margin-left: 3rem;
}
