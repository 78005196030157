.status-feedback {
  background-color: #f1f1f1;
  border-radius: 0.5rem;
  padding: 1rem;
  display: table;
  max-width: max-content;
}
.status-feedback p {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 700;
  display: table-cell;
  vertical-align: middle;
}
.status-feedback div {
  display: inline-block;
  margin: 0 1rem;
  text-align: center;
}
.status-feedback img {
  margin: 0 0 0 1rem;
}

.contract {
  background-color: #f1f1f1;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  margin-bottom: 2rem;
}

.contract-details {
  display: flex;
  gap: 2rem;
  /* height: 15rem; */
  overflow: auto;
  height: 400px;
}

.contract-details table {
  width: 100%;
  border-spacing: 0;
}

.contract-details table th {
  border: none;
  border-collapse: collapse;
  background-color: #e3e3e3;
  padding: 0.5rem;
  font-size: 0.8rem;
  font-weight: 700;
  width: 33.33%;
  text-align: left;
}

.contract-details table td {
  font-size: 0.8rem;
  padding: 1rem 0.5rem;
}
.group {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 400px;
  /* overflow: auto; */
}
.group table {
  margin-bottom: 1rem;
}

.contract-details-assets {
  height: max-content;
}
.contract-details-assets tr:not(:last-child) td {
  border-bottom: 0.1rem solid #b6b5b2;
}

.contract-assets h2 {
  background-color: #b6b5b2;
  color: white;
  font-size: 0.8rem;
  text-align: center;
  padding: 0.5rem;
  margin: 1rem 0 0 0;
}
.contract-assets table {
  width: 100%;
  border-spacing: 0;
  table-layout: fixed;
  text-align: center;
  font-size: 0.8rem;
}
.contract-assets table th {
  border: none;
  border-collapse: collapse;
  background-color: #e3e3e3;
  padding: 0.5rem 0;
  font-weight: 700;
}

.contract-assets td {
  padding: 0.5rem;
}
.contract-assets tr:not(:last-child) td {
  border-bottom: 0.1rem solid #b6b5b2;
}

.contract-assets img {
  width: 80%;
  height: 80%;
  object-fit: cover;
}

.btn {
  background-color: #152a5c;
  color: white;
  border-radius: 0.5rem;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0.5rem;
  border: none;
  width: 100%;
}

.file-actions {
  display: flex;
  gap: 2rem;
}

.file-input {
  display: flex;
  width: 100%;
  gap: 1rem;
  align-items: center;
}

.orfile-input {
  display: flex;
  width: 100%;
  gap: 1rem;
  align-items: center;
}


.file-input input {
  display: none;
}

.orfile-input input {
  display: none;
}

.file-input label {
  background-color: #152a5c;
  color: white;
  border-radius: 0.5rem;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0.5rem;
  cursor: pointer;
  width: 50%;
  text-align: center;
}
.file-input p {
  margin: 0;
  font-size: 0.8rem;
  color: #152a5c;
  font-weight: 600;
}

.orfile-input label {
  background-color: #152a5c;
  color: white;
  border-radius: 0.5rem;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0.5rem;
  cursor: pointer;
  width: 50%;
  text-align: center;
}
.orfile-input p {
  margin: 0;
  font-size: 0.8rem;
  color: #152a5c;
  font-weight: 600;
}


.search-bar {
  background-image: url("../assets/search.svg");
  background-position: 0.75rem center;
  background-repeat: no-repeat;
  width: 15rem;
  background-color: #f1f1f1;
  border-radius: 0.5rem;
  border: none;
  padding: 1rem 2rem 1rem 2.5rem;

}

.search {
  display: flex;
  align-items: center;
}
.spinner {
  width: 1rem;
  height: 1rem;
  border: 3px solid #152a5c;
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  position: absolute;
  left: 16rem;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.label-image {
  padding: 0.25rem;
  font-size: 0.6rem;
  font-weight: 500;
  color: white;
  border-radius: 0.5rem;
  background-color: #4f964f;
  cursor: pointer;
  display: block;
  width: 80%;
  margin: auto;
}

.upload-image,
.upload-file {
  display: none;
}

.label-file {
  padding: 0.25rem;
  font-size: 0.6rem;
  font-weight: 500;
  color: white;
  border-radius: 0.5rem;
  background-color: #4f964f;
  cursor: pointer;
  display: block;
  width: 80%;
  margin: auto;
}

.label-orfile {
  padding: 0.25rem;
  font-size: 0.6rem;
  font-weight: 500;
  color: white;
  border-radius: 0.5rem;
  background-color: #4f964f;
  cursor: pointer;
  display: block;
  width: 80%;
  margin: auto;
}



.view-file-btn {
  width: 80%;
  margin: 0.25rem auto;
  display: block;
  background-color: #152a5c;
  border-radius: 0.5rem;
  font-size: 0.6rem;
  font-weight: 500;
  color: white;
  padding: 0.25rem;
  border: none;
}

.remove-file-btn {
  width: 80%;
  margin: auto;
  display: block;
  background-color: #dd3333;
  border-radius: 0.5rem;
  font-size: 0.6rem;
  font-weight: 500;
  color: white;
  padding: 0.25rem;
  border: none;
}

.selected-filename {
  font-size: 0.6rem;
  font-weight: 700;
}

.selected-orfilename {
  font-size: 0.6rem;
  font-weight: 700;
}


.submit-btn {
  background-color: #152a5c;
  width: 100%;
  color: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin-top: 1rem;
  font-size: 0.8rem;
  font-weight: 700;
  border: none;
}

.submit-btn:disabled {
  background-color: #b6b5b2;
}


select {
  font-size: 0.8rem;
  border: none;
  background-color: transparent;
}


option {
  font-size: 0.8rem;
  padding: 1rem 0.5rem;
}


/* Remarks */

.remarks-section {
  margin-top: 16px;
  /* display: flex; */
  align-items: flex-start;
}

.remarks-section textarea {
  width: 70%;
  height: 60%;
  margin-top: 16px;
  margin-bottom: 8px;
  display: flex;
}

.remarks-section textarea::placeholder {
  color: #363636;
  padding-top: 16px;
  padding-left: 8px;
}

.remarks-section label{
  font-size: 16px;
}

.remarks-section button {
  width: 70%;
}

.remarks-text{
  margin-top: 65px;
  font-size: 16px;
}

.remarks-text div{
  margin-top: 16px;
  font-size: 16px;
}