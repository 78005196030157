
.main {
  box-sizing: border-box;
  width: 1400px;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: 32px;
}


.main .title {
  width: 100%;
  display: flex; 
  flex-direction: row;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-bottom: -80px;
  align-items: center;
  justify-content: center;
}

.main p {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 8px;
  width: 75%;
  padding-left: 220px;
  margin-top: -10px;
  margin-left: 65px;
}


.main .buttons {
  width: 16%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}


.buttons button {
  background-color: #4472c4;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 500;
  margin-right: 16px;
  width: 100%;
  z-index: 1;
}

.buttons button .back {
  padding-left: 26px!important;
  padding-right: 26px!important;
}


.container {
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  gap: 8%;
}


.navigation {
  justify-content: center;
  width: 800px;
}

.main .table {
  width: 70%!important;
}

.main .tableContainer {
  display: flex; 
  justify-content: center;
  align-items: center; 
  width: 50%;
  box-sizing: border-box;
}

.radiobuttons {
  display: flex;
  flex-direction: row;
  gap: 40%;
  text-align: center;
  justify-content: center;
  margin-top: -32px;
  font-family: system-ui, sans-serif;
  font-size: 16px;
  font-weight: bold;
}

.radio label {
  padding-right: 16px;
  padding-left: 16px;
}


.table {
  text-align: center;
  border-collapse: collapse;
  font-size: 16px;
  height: 500px;
  margin-top: 40px;
}

.table th {
  background-color: black;
  color: white;
  padding: 8px
}

.table td {
  padding: 8px;
}

.table td,
table th {
  border: 0.05rem solid black;
  font-size: 16px;
  padding: 0.125rem;
  font-weight: 500;
}

.table .label {
  text-align: left;
  padding-left: 16px;
  width: 45%;
}

.actual {
  background-color: #0070c0;
  color: white;
  width: 20%;
  text-align: right;
}

.budget {
  background-color: #ffc000;
  color: white;
  width: 20%;
  text-align: right;
}

.green {
  color: green;
}

.black {
  color: black;
}

.red {
  color: red;
}

.DateRange .rdrMonthAndYearWrapper,
.rdrMonths {
  display: none !important;
}

/* .rdrMonths .rdrMonthsVertical {
  display: none !important;

} */
.radiobuttons .radio {}
