.table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  min-height: 380px;
}

.table thead th {
  background-color: #4472c4;
  color: white;
  border-style: solid;
  border-color: #4472c4;
  border-width: 0.05rem;
  padding: 0.5rem;
}
.table tbody th {
  text-align: left;
  border-style: solid;
  border-color: black;
  border-width: 0.05rem;
  padding: 0.25rem 0.5rem;
}

.table tbody td {
  border-style: solid;
  border-color: black;
  border-width: 0.05rem;
}

.greenCell {
  color: green;
}

.redCell {
  color: red;
}

.dButton1{
  margin-bottom: 10px;
  margin-left: 51.3rem;
}
.blackCell {
  color: black;
}
.tableContainer {
  display: flex;
  flex-direction: column;
}

.top {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px; 
}
.grayColumn {
  background-color:lightgrey;
}

.dButton1{
  margin-bottom: 10px;
  margin-left: 51.3rem;
}