.header {
  background-color: #214390;
  height: 4.8rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: bold;
  padding: 0 3.2rem;
  z-index: 100;
  position: fixed;
  top: 0;
  margin-top: 0;
}

.block {
  display: flex;
  align-items: center;
}

.list {
  display: flex;
  margin: 0;
  padding: 0;
}
.list li {
  text-align: center;
  width: max-content;
  margin: 2rem;
}
.list a {
  text-decoration: none;
  color: #ffffff;
}

.list a:hover,
.list a.active {
  color: #b6b5b2;
}

.logo {
  background-color: transparent;
  border-radius: 0.25rem;
  width: 75%;
  height: 75%;
}

.image {
  width: 100%;
  height: 100%;
  padding: 0 0.5rem;
  object-fit: cover;
}

.notification {
  position: relative;
  display: inline-block;
}
.circle {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
}

.profile {
  margin-left: 1rem;
}

.dButton {
  margin-left: 970px;
  margin-top: 40px;
  position: absolute;
  top: 40%;
}
