.accordion {
  width: 100%;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  margin-bottom: 2rem;
}

.item {
  border-radius: 0.5rem;
}

.item table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 0.8rem;
}
.item th {
  background-color: #e3e3e3;
  border: none;
  text-align: left;
  padding: 1rem 2rem;
  position: sticky;
  top: 0;
}
.item td {
  padding: 1rem 2rem;
  background-color: #f1f1f1;
}
.item tr:not(:last-child) td {
  border-bottom: 0.1rem solid #b6b5b2;
}
.title {
  cursor: pointer;
  font-size: 1.5rem;
  color: white;
  background-color: #b6b5b2;
  border-radius: 0.5rem;
  padding: 1rem;
}
.title.active {
  border-radius: 0.5rem 0.5rem 0 0;
}

.title h3 {
  margin: 0;
}

.content {
  max-height: 0;
  overflow: auto;
  border-radius: 0 0 0.25rem 0.25rem;
  transition: max-height 0.2s ease-in-out;
}

.content.active {
  max-height: 500px;
  transition: max-height 0.2s ease-in-out;
}
.content ul {
  padding: 0;
}
.content li {
  display: flex;
  justify-content: space-between;
}
.content li p {
  margin: 0.5rem;
}
